<template>
  <div class="cart-thank-you">
    <div class="icon"></div>
    <h1
      class="headline"
      v-html="$t('terminal.cart.thankyou.headline')"
    ></h1>
    <div
      class="description"
      v-html="`${$t('terminal.thankyou.window.part.1')} ${count}
         ${$t('terminal.thankyou.window.part.2')}`"
    ></div>
    <!-- abort START -->
    <div
      class="abort"
    >
      <button
        class="btn-inverted btn-checkout"
        v-on:click="logoutUser()"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
        v-html="$t('terminal.thankyou.window.abort')"
      ></button>
    </div>
    <!-- abort END -->
  </div>
</template>

<script>
export default {
  name: 'cart-thank-you-app',
  data() {
    return {
      count: 10,
    };
  },
  created() {
    // redirect to google play / app store
    this.redirectCountDown();
  },
  computed: {
    orders() {
      if (this.$store.getters.orders) {
        return this.$store.getters.orders;
      }
      return this.$store.getters.orders;
    },
  },
  methods: {
    logoutUser() {
      // clear cart
      // if (this.$store.state.storeCart.items) {
      //   this.$store.dispatch('clearCart');
      // }

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('boxLang'));
      // Logout data
      this.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');

            // clear other important data
            localStorage.removeItem('qrcodeActive');

            setTimeout(() => {
              this.count = 0;
              // redirect to home
              // this.$router.push('/');
              // redirect to home + reload app
              window.location.assign('/');
            }, 350);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            this.errorStatus = error.response.data;
            console.log(this.errorStatus);
          }
        });
    },

    redirectCountDown() {
      const timeInterval = setInterval(() => {
        this.count -= 1;
        if (this.count <= 0) {
          clearInterval(timeInterval);
          // logout && redirect to screensaver
          this.logoutUser();
        }
      }, 1000);
      // window.location.href = redirect;
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },
  },
  mounted() {
    // animation
    setTimeout(() => {
      const note = document.querySelector('.note');
      if (note) {
        note.classList.add('loaded');
      }
    }, 350);
  },
};
</script>
